import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { clarity } from 'react-microsoft-clarity';

import LoadRoutes from '@/routes';
import { persistor, store } from '@/stores/configureStore';
import ThemeProvider from '@/ThemeProvider';
import { WrapperLayout } from '@/layouts';
import configs from './configs';

const App: React.FC = () => {
  useEffect(() => {
    if (configs.msClarityId) {
      clarity.init(configs.msClarityId);
    }
  }, []);
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider>
          <WrapperLayout>
            <ToastContainer />
            <LoadRoutes />
          </WrapperLayout>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
