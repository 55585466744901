const configs = {
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:3001/v1',
  socketUrl: process.env.REACT_APP_SOCKET_URL || 'http://localhost:3001',
  msClarityId: process.env.REACT_APP_MS_CLARITY_ID,
  limit: 20,
  reRender: 'reRender',
  maxLimit: 1000000,
};

export default configs;
